<template>
    <main class="event">
        <section
            class="event__banner"
            :style="{
                backgroundImage:
                    eventData.event.bannerImage && eventData.event.bannerImage.url
                        ? `url(${eventData.event.bannerImage.url})`
                        : '',
            }"
        >
            <h1
                class="event__banner-title"
                :class="{
                    'text-black': !eventData.event.bannerImage || !eventData.event.bannerImage.url,
                    'text-white': eventData.event.bannerImage && eventData.event.bannerImage.url,
                }"
            >
                {{ h1 }}
            </h1>
        </section>

        <section class="grid-section" v-if="eventData.featured_stores && eventData.featured_stores.length">
            <ul class="grid-section__list">
                <li
                    class="grid-section__list-item"
                    v-for="(item, index) in eventData.featured_stores"
                    :key="index"
                >
                    <CardNewHomepageGridCard :info="item" />
                </li>
            </ul>
        </section>

        <section class="best-coupons" v-if="discounts && discounts.length">
            <h2 class="event__heading-h2">{{ h2 }}</h2>

            <ul class="best-coupons__list">
                <template v-for="(item, key) in discounts" :key="key">
                    <li :style="{ order: key + 1 }">
                        <DCardAdmin :info="item" />
                    </li>
                </template>
            </ul>
        </section>

        <template v-if="eventData.event.contents && eventData.event.contents.length">
            <div v-for="(contentData, index) in eventData.event.contents" :key="index">
                <LayoutAdminContent :content="contentData" class="event__dynamic-content" />
            </div>
        </template>

        <div class="event__breadcrumbs">
            <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" margin="mt-1" />
        </div>
    </main>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'
import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()

const Route = useRoute()
const { buildHeaders, baseUrl, endpoints } = useApiClient()

const loadEvent = (params?: { page: number }) =>
    $fetch<Api.Responses.Pages.EventDetails | Api.Responses.ResourceNotFound>(
        endpoints.pages.event.path + '/' + Route.params.slug,
        {
            headers: buildHeaders(),
            baseURL: baseUrl,
            method: 'GET',
            params: {
                ...params,
                include: 'store.image',
            },
        },
    )

const { data: responseData, error } = await useAsyncData('event-page-data', () =>
    loadEvent().catch((e) => e.data),
)

if (
    error.value ||
    !responseData.value ||
    responseData.value.error ||
    responseData.value.feedback !== 'data_success'
) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const eventData = ref(responseData.value.data)

const discounts = ref(eventData.value.discounts.data)

const {
    public: {
        variant: {
            metaData: { siteName, siteOrigin },
        },
    },
} = useRuntimeConfig()

const currentDates = currentDate()

const [title, description, h1, h2] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [
        eventData.value.event.seo.title || '',
        eventData.value.event.seo.description || '',
        eventData.value.event.seo.h1 || '',
        eventData.value.event.seo.h2 || '',
    ],
)

useSeoMeta({
    title: title,
    description: description,
    ogUrl: siteOrigin + Route.path,
    ogImage: eventData.value.event.image?.url,
    ogImageSecureUrl: eventData.value.event.image?.url,
    ogTitle: title,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.eventosSlug.home,
        },
        {
            path: `/${$lang.routes.events}`,
            title: $lang.pages.eventosSlug.events,
        },
        {
            title: eventData.value.event.title || '',
        },
    ]
})
</script>

<style lang="postcss" scoped>
.event {
    @apply container space-y-6 pb-8 lg:max-w-5xl lg:space-y-8;

    &__heading-h2 {
        @apply mb-6 text-center text-lg lg:mb-8 lg:text-[28px];
    }

    &__banner {
        @apply flex h-[170px] w-full flex-col items-center justify-center bg-no-repeat lg:container lg:max-w-5xl lg:rounded-[5px];

        &-title {
            @apply text-center text-[34px] font-semibold uppercase;
        }
    }

    & .grid-section {
        &__list {
            @apply m-[0_-5px_25px] flex flex-wrap justify-center;

            &-item {
                @apply mb-[5px] min-w-[150px] max-w-[166px] flex-1 p-[5px_5px_0] md:max-w-[188px];
            }
        }
    }

    & .best-coupons {
        @apply space-y-6 lg:space-y-8;
        &__list {
            @apply flex flex-col gap-y-3;
        }
    }

    &__dynamic-content {
        @apply rounded-lg bg-white px-2 py-3 md:px-4 md:py-6 xl:px-8 xl:py-6;
    }
}
</style>
